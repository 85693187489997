const API_ORIGIN = process.env.NEXT_PUBLIC_API_ORIGIN;
const API_URL_SIGNATURE_ENABLED =
  process.env.NEXT_PUBLIC_API_URL_SIGNATURE_ENABLED;

const ACCOUNT_URL = process.env.NEXT_PUBLIC_ACCOUNT_URL;
const APP_ID = process.env.NEXT_PUBLIC_APP_ID;
const AUTH_APP_IDS = process.env.NEXT_PUBLIC_AUTH_APP_IDS;
const AUTH_SIGN_OUT_URL = process.env.NEXT_PUBLIC_AUTH_SIGN_OUT_URL;
const CHECK_MISSING_INFO =
  process.env.NEXT_PUBLIC_CHECK_MISSING_INFO === "true";
const CLIENT_KEY = process.env.NEXT_PUBLIC_CLIENT_KEY;
const FACEBOOK_CLIENT_ID = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID;
const FF_ENABLE_NEW_AUTH_SERVICE =
  process.env.NEXT_PUBLIC_FF_ENABLE_NEW_AUTH_SERVICE === "true";
const GOOGLE_WEB_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID;

const RECAPTCHA_CLIENT_ID = process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT_ID;

const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
const FIREBASE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;
const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET_ID =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_ID;

const FIREBASE_CROSS_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_CROSS_API_KEY;
const FIREBASE_CROSS_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_CROSS_APP_ID;
const FIREBASE_CROSS_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_AUTH_DOMAIN;
const FIREBASE_CROSS_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_MEASUREMENT_ID;
const FIREBASE_CROSS_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_MESSAGING_SENDER_ID;
const FIREBASE_CROSS_PROJECT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_PROJECT_ID;
const FIREBASE_CROSS_STORAGE_BUCKET_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_STORAGE_BUCKET_ID;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE_VERSION = process.env.NEXT_PUBLIC_SENTRY_RELEASE_VERSION;

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
const MUSIXMATCH_URL = process.env.NEXT_PUBLIC_MUSIXMATCH_WEBSITE_URL;

module.exports = {
  ACCOUNT_URL,
  API_ORIGIN,
  API_URL_SIGNATURE_ENABLED,
  APP_ID,
  APP_URL,
  AUTH_APP_IDS,
  AUTH_SIGN_OUT_URL,
  CHECK_MISSING_INFO,
  CLIENT_KEY,
  COOKIE_DOMAIN,
  FACEBOOK_CLIENT_ID,
  FF_ENABLE_NEW_AUTH_SERVICE,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_CROSS_API_KEY,
  FIREBASE_CROSS_APP_ID,
  FIREBASE_CROSS_AUTH_DOMAIN,
  FIREBASE_CROSS_MEASUREMENT_ID,
  FIREBASE_CROSS_MESSAGING_SENDER_ID,
  FIREBASE_CROSS_PROJECT_ID,
  FIREBASE_CROSS_STORAGE_BUCKET_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  GOOGLE_WEB_CLIENT_ID,
  MUSIXMATCH_URL,
  RECAPTCHA_CLIENT_ID,
  SENTRY_DSN,
  SENTRY_RELEASE_VERSION,
};
