import {
  GenericError,
  RitmoProvider,
} from "@mxmdev/react-universal-components";
import {
  AuthProps,
  decodeURL,
  getServerSideAuthProps,
  initI18n,
  withAuthPage,
} from "@mxmdev/react-universal-core";
import { MODE_SHARED } from "@mxmdev/react-universal-core/auth-ssr/constants";
import { removeAuthCookie } from "@mxmdev/react-universal-core/auth-ssr/server/utils";
import { useLink } from "@mxmdev/react-universal-navigation";
import { NextPage } from "next";
import dynamic from "next/dynamic";

import translations from "../i18n/translations";
import {
  ACCOUNT_URL,
  API_ORIGIN,
  API_URL_SIGNATURE_ENABLED,
  APP_ID,
  AUTH_SIGN_OUT_URL,
  CLIENT_KEY,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_CROSS_API_KEY,
  FIREBASE_CROSS_APP_ID,
  FIREBASE_CROSS_AUTH_DOMAIN,
  FIREBASE_CROSS_MEASUREMENT_ID,
  FIREBASE_CROSS_MESSAGING_SENDER_ID,
  FIREBASE_CROSS_PROJECT_ID,
  FIREBASE_CROSS_STORAGE_BUCKET_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  SENTRY_DSN,
  SENTRY_RELEASE_VERSION,
} from "../processEnv";
import { AuthMode } from "../src/auth-mode/types";

initI18n(translations);

// TODO: remove dynamic import when SSR will work 100%.
const App = dynamic(() => import("../App"), {
  ssr: false,
});

const Index: NextPage<Props & { auth: AuthProps }> = ({ mode }) => {
  return (
    <App
      analyticsConfig={{
        firebase: [
          {
            apiKey: FIREBASE_API_KEY,
            appId: FIREBASE_APP_ID,
            authDomain: FIREBASE_AUTH_DOMAIN,
            measurementId: FIREBASE_MEASUREMENT_ID,
            messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
            projectId: FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_STORAGE_BUCKET_ID,
          },
          {
            apiKey: FIREBASE_CROSS_API_KEY,
            appId: FIREBASE_CROSS_APP_ID,
            authDomain: FIREBASE_CROSS_AUTH_DOMAIN,
            measurementId: FIREBASE_CROSS_MEASUREMENT_ID,
            messagingSenderId: FIREBASE_CROSS_MESSAGING_SENDER_ID,
            projectId: FIREBASE_CROSS_PROJECT_ID,
            storageBucket: FIREBASE_CROSS_STORAGE_BUCKET_ID,
          },
        ],
      }}
      apiOrigin={API_ORIGIN!}
      apiURLSignatureEnabled={API_URL_SIGNATURE_ENABLED === "true"}
      appId={APP_ID!}
      clientKey={CLIENT_KEY}
      errorTrackingConfig={{
        tracingSampleRate: 0.1,
        url: SENTRY_DSN,
        version: SENTRY_RELEASE_VERSION,
      }}
      mode={mode}
      signOutURL={AUTH_SIGN_OUT_URL || undefined}
    />
  );
};

interface Props {
  isLoggedIn: boolean;
  mode: AuthMode;
}

export const getServerSideProps = getServerSideAuthProps<Props>(
  async (ctx, auth) => {
    const mode: AuthMode = ctx.query.mode === MODE_SHARED ? "shared" : "legacy";
    const isLoggedIn =
      ctx.query.mode === "legacy"
        ? false
        : (auth.hasAuthCookie || !!auth.isExternal) && !!auth.user;

    if (auth.hasAuthCookie && !auth.user) {
      // The user is not logged in but an auth cookie is set
      removeAuthCookie(ctx.res);
    }

    if (isLoggedIn) {
      return {
        redirect: {
          destination:
            typeof ctx.query.origin === "string"
              ? ctx.query.origin.startsWith("http")
                ? ctx.query.origin
                : decodeURL(ctx.query.origin)
              : ACCOUNT_URL!,
          permanent: false,
        },
      };
    }

    return {
      props: {
        auth,
        isLoggedIn,
        mode,
      },
    };
  },
  {
    allowExternalAuthParams: true,
    apiOrigin: API_ORIGIN!,
    appId: APP_ID!,
    enabled: !__DEV__,
    forceSignIn: false,
  }
);
const Error = () => (
  <RitmoProvider useLink={useLink}>
    <GenericError />
  </RitmoProvider>
);

export default withAuthPage(Index, Error, {
  accountURL: "",
  apiOrigin: API_ORIGIN!,
  appId: APP_ID!,
  clientKey: CLIENT_KEY!,
  ignoreTokenMigration: true,
  signInURL: "",
  signOutURL: AUTH_SIGN_OUT_URL!,
  tokenMigrateURL: "",
});
