/* THIS FILE IS AUTOGENERATED. DO NOT CHANGE. */

export default (i18n: any) => {
  i18n.addResourceBundle(
    "ar",
    "auth",
    require("./translations/ar-SA/auth.json")
  );
  i18n.addResourceBundle(
    "bn",
    "auth",
    require("./translations/bn-BD/auth.json")
  );
  i18n.addResourceBundle(
    "cs",
    "auth",
    require("./translations/cs-CZ/auth.json")
  );
  i18n.addResourceBundle(
    "da",
    "auth",
    require("./translations/da-DK/auth.json")
  );
  i18n.addResourceBundle(
    "de",
    "auth",
    require("./translations/de-DE/auth.json")
  );
  i18n.addResourceBundle(
    "en",
    "auth",
    require("./translations/en-US/auth.json")
  );
  i18n.addResourceBundle(
    "es",
    "auth",
    require("./translations/es-ES/auth.json")
  );
  i18n.addResourceBundle(
    "fa",
    "auth",
    require("./translations/fa-IR/auth.json")
  );
  i18n.addResourceBundle(
    "fr",
    "auth",
    require("./translations/fr-FR/auth.json")
  );
  i18n.addResourceBundle(
    "hi",
    "auth",
    require("./translations/hi-IN/auth.json")
  );
  i18n.addResourceBundle(
    "id",
    "auth",
    require("./translations/id-ID/auth.json")
  );
  i18n.addResourceBundle(
    "it",
    "auth",
    require("./translations/it-IT/auth.json")
  );
  i18n.addResourceBundle(
    "ja",
    "auth",
    require("./translations/ja-JP/auth.json")
  );
  i18n.addResourceBundle(
    "ko",
    "auth",
    require("./translations/ko-KR/auth.json")
  );
  i18n.addResourceBundle(
    "nl",
    "auth",
    require("./translations/nl-NL/auth.json")
  );
  i18n.addResourceBundle(
    "pl",
    "auth",
    require("./translations/pl-PL/auth.json")
  );
  i18n.addResourceBundle(
    "pt",
    "auth",
    require("./translations/pt-BR/auth.json")
  );
  i18n.addResourceBundle(
    "ro",
    "auth",
    require("./translations/ro-RO/auth.json")
  );
  i18n.addResourceBundle(
    "ru",
    "auth",
    require("./translations/ru-RU/auth.json")
  );
  i18n.addResourceBundle(
    "sv",
    "auth",
    require("./translations/sv-SE/auth.json")
  );
  i18n.addResourceBundle(
    "ta",
    "auth",
    require("./translations/ta-IN/auth.json")
  );
  i18n.addResourceBundle(
    "th",
    "auth",
    require("./translations/th-TH/auth.json")
  );
  i18n.addResourceBundle(
    "tl",
    "auth",
    require("./translations/tl-PH/auth.json")
  );
  i18n.addResourceBundle(
    "tr",
    "auth",
    require("./translations/tr-TR/auth.json")
  );
  i18n.addResourceBundle(
    "vi",
    "auth",
    require("./translations/vi-VN/auth.json")
  );
  i18n.addResourceBundle(
    "zh",
    "auth",
    require("./translations/zh-TW/auth.json")
  );
};
